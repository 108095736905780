import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Api, errorToast } from "../utils/api";
import { useAuth } from "../utils/hooks/useAuth";


export const AdminLogin = () => {
    const [userid, setUserid] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    
    
    const loginMutate = useMutation({
        mutationFn:(loginRequest)=>Api.post('/api/auth/signin', loginRequest),
        onSuccess:(res)=>{
            login(res.data);
        },
        onError:errorToast
    })
    const onLogin = ()=>{
        if(loginMutate.isPending)return;

        loginMutate.mutate({userid, password});
    }
    return (
        <div className="loginPage shadow">
            <div className="mb-3">
                <label htmlFor="loginUserId" className="form-label">아이디</label>
                <input className="form-control" id="loginUserId" name="userid" value={userid} onChange={e=>setUserid(e.target.value)} />
            </div>

            <div className="mb-3">
                <label htmlFor="loginUserPassword" className="form-label">비밀번호</label>
                <input type="password" className="form-control" id="loginUserPassword" name="password" value={password} onChange={e=>setPassword(e.target.value)} />
            </div>
            <div className="mb-3 text-end">
                <button className="btn btn-primary" onClick={onLogin} >로그인</button>
            </div>
        </div>
    );
}