import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Api, errorToast } from "../../utils/api";
import { Loading } from "../../common/Loading";
import { useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const initialState = {
    reserveTime:10,
    month: "2",
    ktAdmin: "N",
    useReserveFind: "N"
};

const formReducer = (state, action) => {
    return { ...state, ...action };
};


export const ConfigLogicForm = () => {
    const [state, setState] = useReducer(formReducer, initialState);
    const { data: config, isLoading, isSuccess } = useQuery({
        queryKey: ['config/logic'],
        queryFn: () => Api.get('/api/config/config-logic'),
        refetchOnMount: 'always'
    });

    useEffect(() => {
        if (!isSuccess) return;
        setState(config?.data);
    }, [isSuccess, config])

    const handleInput = (e) => {
        const target = e.target;
        setState({ [target.name]: target.value });
    };

    const qc = useQueryClient();
    const storeMutate = useMutation({
        mutationFn: (data) => Api.post('/api/manage/config/store-logic', data),
        onSuccess: (res) => {
            qc.invalidateQueries({ queryKey: ['config/logic'] });
            toast.success("저장 했습니다.")
        },
        onError: errorToast
    });

    const onStore = (e) => {
        e.preventDefault();
        if (storeMutate.isPending) return;
        
        storeMutate.mutate(state);
    }

    if (isLoading) return <Loading />;

    return (
        <div className="configFormPage mt-3">
            <div className="shadow p-3 m-4 p-5 bg-white border rounded">
                <form onSubmit={onStore}>
                    <h2 className="title mb-5 text-center">
                        운영정책 수정
                    </h2>
                    <div className="mb-2 row">
                        <label htmlFor="name" className="col-sm-3 col-form-label">예약기간(월)</label>
                        <div className="col-sm-9">
                            <div className="d-flex justify-content-evenly">
                                <input type="checkbox" className="btn-check" id="month2" name="month" value="2" checked={state.month === "2"} onChange={handleInput} />
                                <label className="btn" htmlFor="month2">2</label>

                                <input type="checkbox" className="btn-check" id="month3" name="month" value="3" checked={state.month === "3"} onChange={handleInput} />
                                <label className="btn" htmlFor="month3">3</label>

                                <input type="checkbox" className="btn-check" id="month4" name="month" value="4" checked={state.month === "4"} onChange={handleInput} />
                                <label className="btn" htmlFor="month4">4</label>

                                <input type="checkbox" className="btn-check" id="month5" name="month" value="5" checked={state.month === "5"} onChange={handleInput} />
                                <label className="btn" htmlFor="month5">5</label>

                                <input type="checkbox" className="btn-check" id="month6" name="month" value="6" checked={state.month === "6"} onChange={handleInput} />
                                <label className="btn" htmlFor="month6">6</label>
                            </div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="name" className="col-sm-3 col-form-label">당일 예약 시간</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control" name="reserveTime" value={state.reserveTime} onChange={handleInput} />
                            <div className="text-body-secondary fst-italic">* 당일예약 가능한 시간을 지정 0~23시</div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="name" className="col-sm-3 col-form-label">관리자 알림톡</label>
                        <div className="col-sm-9">
                            <input type="checkbox" className="btn-check" id="ktAdmin_yes" name="ktAdmin" value="Y" checked={state.ktAdmin === "Y"} onChange={handleInput} />
                            <label className="btn" htmlFor="ktAdmin_yes">받기</label>

                            <input type="checkbox" className="btn-check" id="ktAdmin_no" name="ktAdmin" value="N" checked={state.ktAdmin !== "Y"} onChange={handleInput} />
                            <label className="btn" htmlFor="ktAdmin_no">받지않기</label>
                            <div className="text-body-secondary fst-italic">* 이용자에게 발송되는 알림톡을 관리자도 같이 받습니다.</div>
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="name" className="col-sm-3 col-form-label">예약 조회 사용</label>
                        <div className="col-sm-9">
                            <input type="checkbox" className="btn-check" id="useReserveFind_yes" name="useReserveFind" value="Y" checked={state.useReserveFind === "Y"} onChange={handleInput} />
                            <label className="btn" htmlFor="useReserveFind_yes">사용</label>

                            <input type="checkbox" className="btn-check" id="useReserveFind_no" name="useReserveFind" value="N" checked={state.useReserveFind !== "Y"} onChange={handleInput} />
                            <label className="btn" htmlFor="useReserveFind_no">미사용</label>
                        </div>
                    </div>


                    <div className="text-center mt-3">
                        <button className="btn btn-success" onClick={onStore}>
                            {storeMutate.isPending && <Loading inline={true} />} 저장
                        </button>
                        &nbsp;
                        <Link className="btn btn-secondary" to="/admin">목록</Link>
                    </div>
                </form>
            </div>
        </div>
    )
}