import { useEffect, useState } from 'react';

export const Loading = ({inline=false, overlay=false, active=false, className}) => {
    const [display, setDisplay] = useState(true);

    useEffect(() => {
        if(active){
            setDisplay(true);
        }else{
            // 로딩이 비활성화 되면 .off class를 추가하고 이벤트를 처리하는데
            // transitionEnd가 안될 경우를 대비해서 0.7초 후에는 무조건 로딩 비활성화
            setTimeout(() => setDisplay(false) , 500);
        }
    }, [active]);
    const onTransitionEnd = (e)=>{
        if( e.target.classList.contains('off') ){
            setDisplay(false);
        }
            
    }
    if(!display) return null;


    if(inline){
        return (
            <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
        );
    }
    return (
        <div className={`container text-center loading ${className} ${overlay?'overlay':''} ${active?'on':'off'}`} onTransitionEnd={onTransitionEnd}>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}