import { Route, Routes } from 'react-router-dom';
import { ReserveList } from './ReserveList';
import { ReserveForm } from './ReserveForm';
import { ReserveFind } from './ReserveFind';

export const ReservePage = () => {
    return (
        <div className="userPage">
            <Routes>
                <Route path='/' element={<ReserveList />} />
                <Route path='/:id' element={<ReserveForm />} />
                <Route path='/find' element={<ReserveFind />} />
            </Routes>
        </div>
    )
}