import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from './HomePage';
import { ReservePage } from './reserve/ReservePage';
import { AdminPage } from './admin/AdminPage';
import { About } from './about/About';
import { AuthProvider } from './utils/AuthProvider';
import { Header } from './common/Header';
import { Footer } from "./common/Footer";
import { ConfigContext } from './utils/ConfigProvider';
import { Helmet } from 'react-helmet-async';

function App() {
    const { configInfo } = useContext(ConfigContext);
    
    return (
        <>
        {configInfo && 
        <Helmet>
            <title>{configInfo?.name || ''} 예약</title>
            <meta name="description" content={configInfo?.desc} />
        </Helmet>
        }
        <div className="App d-flex flex-column">
            <AuthProvider>
                <Header />
                <div className="contentBody">
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/reserve/*' element={<ReservePage />} />
                        <Route path="/admin/*" element={<AdminPage />} />
                    </Routes>
                </div>
            </AuthProvider>
            <Footer />
        </div>
        </>
    );
}

export default App;