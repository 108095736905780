import { Link, useNavigate } from "react-router-dom";
import { MonthNav } from "./MonthNav";
import { useQuery } from '@tanstack/react-query';
import { Loading } from "../common/Loading";
import { useQueryString } from "../utils/useQueryString";
import { useContext, useEffect, useMemo, useState } from "react";
import { Api } from "../utils/api";
import { RESERVE_STATE, WEEK_NAMES } from "../utils/const";
import { ConfigContext } from "../utils/ConfigProvider";
import moment from "moment";
import { toast } from "react-toastify";
import { WeekButton } from "./WeekButton";

export const ReserveList = () => {
    const navigate = useNavigate();
    const { configLogic } = useContext(ConfigContext);
    const [weeks, setWeeks] = useState([]);
    
    const qs = useQueryString();
    const requestData = useMemo(() => {
        const year = qs.year || String(new Date().getFullYear());
        const month = qs.month || String(new Date().getMonth() + 1).padStart(2, "0");
        return { year, month };
    }, [qs]);

    const { data: list, isLoading, isError } = useQuery({
        queryKey: ['reserve/list', requestData],
        queryFn: () => Api.get(`/api/reserve/list?year=${requestData.year}&month=${requestData.month}`),
        refetchOnMount:'always',
    });

    // 목록을 만들고
    const dateList = useMemo(() => {
        console.log(weeks);
        return (
            list?.data?.map(item => {
                if( weeks.length>0 && !weeks.includes(item.week))return null;

                const reserveDate = moment(item.date);
                if( moment().startOf('day').diff(reserveDate.startOf('day'), "days")===0 ){
                    //당일이면 시간을 비교 해보자
                    let reserveTime = Number(configLogic?.reserveTime);
                    if( Number.isNaN(reserveTime) || reserveTime>23 ) reserveTime=0;
                    if( moment().diff(reserveDate.hours(reserveTime), 'hours')>0 ){
                        //당일 예약이면
                        return null;
                    }
                }
                
                return (
                    <div className={`reserve border rounded ${item.state}`} key={item.date}>
                        <div className="date mt-3">
                            {item.state==='OPEN' && 
                            <Link to={`/reserve/${item.id}`}>
                                <h5 className="day">{item.date.substring(8)}<small className={`ms-1 week week${item.week}`}>({WEEK_NAMES[item.week]})</small></h5>
                            </Link>
                            }
                            {item.state!=="OPEN" && <h5 className="day">{item.date.substring(8)}<small className={`ms-1 week week${item.week}`}>({WEEK_NAMES[item.week]})</small></h5>}
                        </div>
                        <div className="mt-2 mb-2">
                            {item.state==='OPEN' && 
                                <div>
                                    <div className="fishType">{item.fish}</div>
                                    <div className="remaining"><strong>{item.totalCount - Math.max(item.guestCount, 0)}</strong> 명 가능</div>
                                </div>
                            }
                            {item.state!=="OPEN" &&
                            <>
                                <div className={`reserveState ${item.state} `}>{RESERVE_STATE[item.state]}</div>
                                <div className="memo">{item.memo}</div>
                            </>
                            }
                        </div>
                        
                        <div className="d-grid gap-2">
                            {item.state!=="OPEN" && <button className="btn btn-sm btn-primary text-decoration-line-through">예약하기</button> }
                            {item.state==="OPEN" && 
                            <Link className="btn btn-sm btn-primary" to={`/reserve/${item.id}`}>
                                예약하기 
                            </Link>
                            }
                        </div>
                    </div>
                );
            })
        )
    }, [configLogic?.reserveTime, list?.data, weeks]);

    useEffect(() => {
        if(dateList===undefined) return;
        if(dateList?.length===1 && dateList[0]===null){
            toast.error(`${Number(requestData.month)}월 예약이 끝났습니다.`);
            const nextMonth = String(new Date().getMonth() + 2).padStart(2, "0");
            navigate(`/reserve?year=${requestData.year}&month=${nextMonth}`);
        }  
    }, [dateList, requestData, navigate]);

    const toggleWeek = (num)=>{
        if( weeks?.includes(num) ) {
            setWeeks( weeks.filter(v=>v!==num) );
        }else{
            //없으면 추가
            setWeeks([...weeks, num]);
        }
    };

    return (
        <div className="reserveListPage">
            <MonthNav month={requestData.month} />

            <ul className="weekNav nav nav-justified mt-3">
                <WeekButton weeks={weeks} num={1} onClick={toggleWeek} />
                <WeekButton weeks={weeks} num={2} onClick={toggleWeek} />
                <WeekButton weeks={weeks} num={3} onClick={toggleWeek} />
                <WeekButton weeks={weeks} num={4} onClick={toggleWeek} />
                <WeekButton weeks={weeks} num={5} onClick={toggleWeek} />
                <WeekButton weeks={weeks} num={6} onClick={toggleWeek} />
                <WeekButton weeks={weeks} num={7} onClick={toggleWeek} />
            </ul>

            { configLogic?.useReserveFind==='Y' &&
            <div className="text-end">
                <Link className="btn btn-success mt-2" to="/reserve/find">예약 조회/취소</Link>
            </div>
            }
            {isLoading && <Loading className="mt-5"  />}
            {isError && <div className="text-center text-body-secondary m-5">예약 일정을 불러오는데 실패했습니다.</div>}
            {list?.data?.length===0 && <div className="text-center text-body-secondary m-5">예약가능한 날이 없습니다.</div>}

            <div className="reserveList d-flex flex-wrap justify-content-between container mt-3">
                {dateList}
            </div>
        </div>
    );
}