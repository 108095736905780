import { Link } from "react-router-dom";
import { GuestInfo } from "./GuestInfo";
import { ConfigContext } from "../utils/ConfigProvider";
import { useContext } from "react";


export const ReserveResult = ({guest})=>{
    const { configInfo } = useContext(ConfigContext);    
    return (
        <div className="reserveComplete container mt-1 ">
                <div className="mb-4 rounded">
                    <h2 className="text-center text-success m-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
                        </svg>
                        &nbsp;
                        예약완료
                    </h2>
                    <div className="bg-white shadow rounded pt-1 pb-1">
                        <GuestInfo guest={guest} />
                    </div>
                    <div className="mt-5 guideList">
                        <h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-square me-1" viewBox="0 0 16 16">
                                <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                                <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                            </svg>
                            예약자 확인사항
                        </h5>
                        <ul className="list-group list-group-flush rounded shadow bg-white">
                            <li className="list-group-item">예약금 입금이 안되면 <strong className="text-danger">예약 취소</strong> 될 수 있습니다</li>
                            <li className="list-group-item"><strong className="text-danger">예약취소</strong>는 반드시 <strong>전화</strong> 주세요.</li>
                            <li className="list-group-item">온라인/전화 예약이 중복 발생할 경우 예약순서에 따라 취소 될 수 있습니다.</li>
                            <li className="list-group-item">계좌 : <strong>{configInfo?.bank}</strong></li>
                            <li className="list-group-item">* 출항 10분전에 도착 하셔야 합니다.</li>
                            <li className="list-group-item">* 기상이 좋지않을 시 선장의 판단에 따라 시간이 조정 될 수 있습니다.</li>
                            <li className="list-group-item">* 개인정보(이름/연락처)는 출항 3일 후 파기됩니다.</li>
                        </ul>
                    </div>
                    <div className="reserveGuide mb-2 mt-4">
                        <h5>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-square me-1" viewBox="0 0 16 16">
                                <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                                <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                            </svg>
                            취소 및 환불
                        </h5>
                        <ul className=" border border-secondary-subtle rounded shadow bg-white pt-2 pb-2">
                            <li>7일전 100% 환불</li>
                            <li>3일전 50% 환불</li>
                            <li>1일전 & 당일 환불 불가</li>
                        </ul>
                    </div>
                    <div className="text-center mt-3 mb-3">
                        <Link className="btn btn-secondary" to="/" >목록</Link>
                    </div>
                </div>
        </div>
    )
}