import { WEEK_NAMES } from "../utils/const";


export const WeekButton = ({weeks=[], num=0, onClick=f=>f})=>{
    

    return (
    <li className="nav-item week">
        <button className={`nav-link week${num} ${weeks?.includes(num)&& 'active'}`} onClick={()=>onClick(num)}>
            {WEEK_NAMES[num]}
        </button>
    </li>
    );
};