import { Routes, Route } from "react-router-dom";
import { AdminLogin } from "./AdminLogin";
import { Loading } from "../common/Loading";
import { AdminGnb } from "./AdminGnb";
import { AdminReservePage } from "./reserve/AdminReservePage";
import { AdminGuestPage } from "./guest/AdminGuestPage";
import { useAuth } from "../utils/hooks/useAuth";
import { AdminHome } from "./AdminHome";
import { ConfigPage } from "./config/ConfigPage";

export const AdminPage = ()=>{
    const { user } = useAuth();

    if(user===undefined) return <Loading />;
    if(user===null) return <AdminLogin />;

    return (
        <div className="adminPage container p-1">
            <AdminGnb />
            <Routes>
                <Route path="/" element={<AdminHome />} />
                <Route path="/config/*" element={<ConfigPage />} />
                <Route path="/reserve/*" element={<AdminReservePage />} />
                <Route path="/guest/*" element={<AdminGuestPage />} />
            </Routes>
        </div>
    );
}