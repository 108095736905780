import { useContext } from "react";
import { ConfigContext } from "../utils/ConfigProvider";
import { TelButton } from "./TelButton";
import { BandButton } from "./BandButton";

export const Footer = () => {
    const { configInfo } = useContext(ConfigContext);

    return (
        <footer className='footer'>
            <div className="pageContainer p-3">
                <div className="business">
                    <span className="title">{configInfo?.name}</span> 
                    <span>대표 {configInfo?.owner}</span>
                    <span>주소 : {configInfo?.address}</span>
                    <span>사업자등록번호: {configInfo?.bizno}</span>
                </div>
                <div>
                    <TelButton showNumber={true} />
                    <BandButton />
                </div>
            </div>
        </footer>
    )
}