import Axios from "axios";
import { refreshToken, refreshTokenError } from "./apiTokenRefresh";
import { toast } from "react-toastify";

export const Api = Axios.create({
    baseURL:process.env.REACT_APP_API,
    timeout:10*1000,
    // withCredentials:true
});

Api.interceptors.request.use(refreshToken, refreshTokenError);

export const createFetchErrorMessage = (err, defaultMessage = '실패했습니다') => {
    const errorData = {
        message: defaultMessage,
        errors: []
    };
    
    if (err.response) {
        if (err.response.status === 400) {
            if (err.response.data.errors.length > 0) {
                errorData.errors = err.response.data.errors;
                errorData.message = err.response.data.errors[0].reason;
            } else {
                errorData.message = err.response.data.message;
            }
        } else {
            errorData.message = err.response.data.message;
        }
    } else {
        errorData.message = err.defaultMessage;
    };
    return errorData;
}
export const errorToast = (err, defaultMessage = '실패했습니다') => {
    console.log(err);
    toast.error( createFetchErrorMessage(err, defaultMessage).message );
};