import { Link } from "react-router-dom";
import { CountSummary } from "./CountSummary";


export const AdminHome = ()=>{


    return (
        <div className="adminHome">
            <CountSummary />

            <div className="d-flex justify-content-center m-4">
                <Link className="btn btn-primary" to="/admin/config/password">비밀번호 변경</Link>
                &nbsp;
                <Link className="btn btn-secondary" to="/admin/config/info">기본정보</Link>
                &nbsp;
                <Link className="btn btn-secondary" to="/admin/config/logic">운영정책</Link>
            </div>
        </div>
    )
}