import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Api, createFetchErrorMessage } from "../../utils/api";
import { toast } from "react-toastify";


export const ReserveMemoForm = ({date, id}) => {
    const [memo, setMemo] = useState("");
    
    const qc = useQueryClient();

    // 메모
    const updateMemoMutate = useMutation({
        mutationFn: (data) => Api.post("/api/manage/reserve/update-memo", data),
        onSuccess: () => {
            qc.setQueryData(['reserve', date], (oldData) => {
                return { ...oldData, data: { ...oldData.data, memo } };
            })
            toast.success('변경되었습니다.');
        },
        onError: (err) => {
            const errorData = createFetchErrorMessage(err);
            toast.success('실패했습니다.' + errorData.message);
        }
    });

    const onStoreMemo = () => {
        if (updateMemoMutate.isPending) return;
        updateMemoMutate.mutate({ id, memo })
    }

    return (
        <div className="mb-3 row">
            <label htmlFor="name" className="col-sm-2 col-form-label">메모</label>
            <div className="col-9 col-sm-8">
                <input type="text" className="form-control" id="memo" value={memo} onChange={e => setMemo(e.target.value)} maxLength={50} />
            </div>
            <div className="col-3 col-sm-2">
                <button className="btn btn-sm btn-primary" onClick={onStoreMemo}>저장</button>
            </div>
        </div>
    );
}