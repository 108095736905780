import { Routes, Route } from "react-router-dom";
import { AdminReserveList } from "./AdminReserveList";
import { AdminReserveView } from "./AdminReserveView";
import { ReserveUpdateForm } from "./ReserveUpdateForm";

export const AdminReservePage = ()=>{

    return (
        <div>
            <Routes>
                <Route path="/" element={<AdminReserveList />} />
                <Route path="/:date" element={<AdminReserveView />} />
                <Route path="/update" element={<ReserveUpdateForm />} />
            </Routes>
        </div>
    );
};