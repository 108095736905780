import moment from "moment";
import { WEEK_NAMES } from "../utils/const";

export const GuestInfo = ({guest}) => {
    return (
        <div className="guestInfo m-3">
            <div className="row mt-3">
                <div className="col-3">예약번호</div>
                <div className="col-9">
                    <strong>{guest?.guestId}</strong>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-3">예약일시</div>
                <div className="col-9">
                    <strong>{guest?.reserveDate}
                        <small className={`ms-1 week week${guest?.reserveWeek}`}>({WEEK_NAMES[guest?.reserveWeek]})</small>
                    </strong>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-3">인원</div>
                <div className="col-9"><strong className="text-primary">{guest?.guestCount}</strong> 명</div>
            </div>
            <div className="row mt-3">
                <div className="col-3">예약자명</div>
                <div className="col-9"><strong className="text-primary">{guest?.name}</strong></div>
            </div>
            <div className="row mt-3">
                <div className="col-3">연락처</div>
                <div className="col-9"><strong>{guest?.phone}</strong></div>
            </div>
            <div className="row mt-3">
                <div className="col-3">예약시간</div>
                <div className="col-9"><strong>{moment(guest?.createdAt).format('LLL')}</strong></div>
            </div>
        </div>
    )
}