import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Api, errorToast } from "../../utils/api";
import { toast } from "react-toastify";
import { Loading } from "../../common/Loading";

export const GuestModify = ()=>{
    const { id } = useParams();
    const navigate = useNavigate();
    const [guestCount, setGuestCount] = useState(1);
    const [name, setName] = useState("");
    const [tel, setTel] = useState("010-");
    const [maxGuestCount, setMaxGuestCount] = useState(10);

    const { data:guest, isLoading, isSuccess, isError, error } = useQuery({
        queryKey:['guest/get', id],
        queryFn:()=>Api.get(`/api/manage/guest/${id}`),
        refetchOnMount:'always',
        refetchInterval:Infinity,
        refetchIntervalInBackground:false
    });

    const reserveFetch = useQuery({
        queryKey: ['reserve', guest?.data?.date],
        queryFn: () =>{
            if(!guest?.data?.date)return null;
            return Api.get(`/api/reserve/date/${guest?.data?.date}`)
        },
        refetchOnMount: 'always',
    });

    useEffect(() => {
        if (!isSuccess)return;
        if (!reserveFetch.isSuccess) return;
        const tmpCount = reserveFetch?.data?.data?.totalCount - reserveFetch?.data?.data?.guestCount + guest?.data?.guestCount;
        if (tmpCount !== guestCount) setMaxGuestCount(tmpCount);
    }, [reserveFetch, guestCount, isSuccess, guest]);

    useEffect(() => {
        if (!isSuccess)return;
        setName(guest?.data?.name);
        setTel(guest?.data?.phone);
        setGuestCount(guest?.data?.guestCount);
    }, [guest, isSuccess])
    

    const onChangeTel = useCallback((e) => {
        let tempValue = e.target.value.replace(/[^0-9]/g, "");
        if (tempValue.length <= 7) {
            setTel(tempValue.replace(/^(\d{3})(\d{1,4})$/, `$1-$2`));
        } else {
            setTel(tempValue.replace(/^(\d{3})(\d{3,4})(\d{2,4})$/, `$1-$2-$3`));
        }
    }, []);

    const storeMutate = useMutation({
        mutationFn: (data) => Api.post("/api/manage/guest/guest-update", data),
        onSuccess: (res) => {
            toast.success('예약이 수정 되었습니다.');
            navigate(-1);
        },
        onError: errorToast
    });

    const onReserve = () => {
        if (storeMutate.isPending) return;
        if (name.length < 2) return toast.error("예약자 이름을 입력해주세요");
        if (tel.length < 12) return toast.error("휴대폰 번호를 입력해주세요");

        storeMutate.mutate({ id, name, tel, guestCount });
    }

    if(isLoading) return <Loading />;
    if(isError){
        errorToast(error);
        navigate(-1);
    }

    return (
        <div className="reserveFormPage">

            <div className="reserveForm container mt-1 ">
                <div className="shadow p-3 mb-4 bg-white border rounded">
                    <h2 className="title text-center">
                        <span className="date">{guest?.data?.date}</span> 예약 수정
                    </h2>
                    

                    <div className="mb-2 row">
                            <label htmlFor="name" className="col-sm-2 col-form-label">이름</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="name" maxLength={5} value={name} onChange={e => setName(e.target.value)} />
                            </div>
                        </div>

                        <div className="mb-2 row">
                            <label htmlFor="tel" className="col-sm-2 col-form-label">연락처</label>
                            <div className="col-sm-10">
                                <input type="text" className="form-control" id="tel" value={tel} onChange={onChangeTel} maxLength={13} />
                            </div>
                        </div>

                    <div className="mb-2 row">
                        <label htmlFor="guestCount" className="col-12 col-form-label">
                            인원을 선택해주세요.
                            <small className="text-body-secondary fst-italic"> {maxGuestCount}명 까지 가능합니다. </small>
                        </label>
                        <div className="col-12">
                            <div className="guestButtons">
                                {
                                    new Array(maxGuestCount).fill().map((item, key) => {
                                        const num = key + 1;
                                        return <button key={num} className={`btn btn-${guestCount === num ? "" : "outline-"}primary`} onClick={() => setGuestCount(num)}>{num}</button>;
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="m-3 text-center">
                        <span>예약금: <strong>{reserveFetch?.data?.data?.reservePrice*guestCount}만원</strong></span>
                        ,&nbsp;&nbsp;
                        <span>선비: <strong>{reserveFetch?.data?.data?.price*guestCount}만원</strong></span>
                    </div>
                    <div className="m-3 text-body-secondary">
                        * 저장하면 새로운 <span className="text-primary">예약 알림톡</span>이 발송됩니다. <br />
                        * 예약정보에 혼선이 발생할 수 있으므로 수정시 <span className="text-danger">주의</span>가 바랍니다. <br />
                        * 예약인원이 줄어들 경우 예약마감 상태에서는 <span className="text-danger">예약중</span>상태로 자동변경 됩니다.<br />
                        * 중복 예약 혼선 가능성이 있으므로 기존 <span className="text-danger">예약취소</span>후 <span className="text-primary">신규예약</span>를 권장합니다.
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button className="btn btn-primary" onClick={onReserve}>
                        {storeMutate.isPending && <Loading inline={true} />} 저장
                    </button>
                    &nbsp;
                    <button className="btn btn-secondary" onClick={()=>navigate(-1)} >취소</button>
                </div>
            </div>
        </div>
                
    )
}