import { useContext } from "react";
import { ConfigContext } from "../utils/ConfigProvider";

export const BandButton = () => {
    const { configInfo } = useContext(ConfigContext);
    if (!configInfo?.band) return null;
    return (
        <a className="btn btn-contact btn-green" href={configInfo?.band} target="_blank" rel="noreferrer" >
            BAND
        </a>
    );
}