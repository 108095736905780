import moment from "moment";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { ConfigContext } from "../utils/ConfigProvider";


export const MonthNav = ({path="/reserve", month}) => {
    const { configLogic } = useContext(ConfigContext);
    
    let monthCount = Number(configLogic?.month || 6);
    if(path==="/admin/reserve") monthCount += 1 ;

    const currentDate = moment().startOf('month').add(-1, 'month');
    return (
        <ul className="monthNav nav nav-justified mt-3">
            {
                new Array(monthCount).fill().map(() => {
                    currentDate.add(1, 'month').endOf('month');
                    const activeClass = currentDate.format('MM')===month?'active':'';
                    return (
                        <li className="nav-item" key={currentDate.format('MM')}>
                            <Link className={`nav-link  ${activeClass}`} to={`${path}?year=${currentDate.format('YYYY')}&month=${currentDate.format('MM')}`}>{currentDate.format('MM')} 월</Link>
                        </li>
                    )
                })
            }
        </ul>
    )
}