import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';
import { Loading } from "../../common/Loading";
import { useQueryString } from "../../utils/useQueryString";
import { Api } from "../../utils/api";
import { MonthNav } from "../../reserve/MonthNav";
import { RESERVE_STATE, WEEK_NAMES } from "../../utils/const";

export const AdminReserveList = () => {
    const [hideZero, setHideZero] = useState(false);

    const qs = useQueryString();

    const requestData = useMemo(() => {
        const year = qs.year || String(new Date().getFullYear());
        const month = qs.month || String(new Date().getMonth() + 1).padStart(2, "0");
        setHideZero(false);
        return { year, month };
    }, [qs]);


    const { data: list, isLoading, isError } = useQuery({
        queryKey: ['reserve/list', requestData],
        queryFn: () => Api.get(`/api/reserve/list?year=${requestData.year}&month=${requestData.month}`),
        refetchOnMount: 'always',
    });

    return (
        <div className="reserveListPage admin">

            <MonthNav month={requestData.month} path="/admin/reserve" />

            <div className="text-end mt-3">
                <Link className="btn btn-link" to="/admin/reserve/update">인원/어종 변경</Link>
                &nbsp;
                <input type="checkbox" className="btn-check" id="hideZero" autoComplete="off" checked={hideZero} onChange={() => setHideZero(!hideZero)} />
                <label className="btn btn-outline-secondary" htmlFor="hideZero">예약 있는 날</label>
            </div>
            <div className="reserveList d-flex flex-wrap justify-content-between">
                {isLoading && <Loading />}
                {isError && <div>예약 일정을 불러오는데 실패했습니다.</div>}

                {list?.data?.map(item => {
                    if (hideZero && item.guestCount === 0) return null;
                    return (
                        <div className="reserve border rounded " key={item.date}>
                            <div className="date mt-3">
                                <h5 className="day">{item.date.substring(8)}<small className={`ms-1 week week${item.week}`}>({WEEK_NAMES[item.week]})</small></h5>
                            </div>
                            <div><strong>{item.guestCount}</strong> / <strong>{item.totalCount}</strong></div>
                            <div>{item.fish}</div>
                            <div className="">
                                <span className={`reserveState ${item.state}`}>{RESERVE_STATE[item.state]}</span>
                                <div className="memo">{item.memo}</div>
                            </div>
                            <div className="price"><strong className="reservePrice">{item.reservePrice}</strong>/<strong>{item.price}</strong> <small>만원</small></div>
                            <div className="d-grid gap-2">
                                <Link className="btn btn-sm btn-primary btn-block" to={`/admin/reserve/${item.date}`}>관리</Link>
                            </div>
                        </div>
                    );
                })}
            </div>

        </div>
    );
}