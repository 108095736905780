import { useQuery } from "@tanstack/react-query";
import { Api, createFetchErrorMessage } from "../../utils/api";
import { Loading } from "../../common/Loading";
import moment from "moment";
import { GUEST_STATE } from "../../utils/const";
import { GuestControl } from "./GuestControl";
import { useEffect, useMemo, useState } from "react";
import { Pagination } from "../../common/Pagination";
import { Link } from "react-router-dom";

export const GuestList = ({ id = null, guestState = null, date = null, keyword = null }) => {
    const [page, setPage] = useState(0);

    const requestData = useMemo(() => {
        const tempData = { id, state: guestState, date, keyword, page };
        return tempData;
    }, [id, keyword, date, guestState, page]);

    useEffect(() => {
        setPage(0);
    }, [id, keyword, date, guestState]);

    const { data: guestData, isLoading, isSuccess, isError, error } = useQuery({
        queryKey: ['guest/list', requestData],
        queryFn: () => Api.post(`/api/manage/guest/list?page=${requestData.page}`, requestData),
        refetchOnMount: 'always',
    });

    if (isLoading) return <Loading />;


    return (
        <div className="mt-3">
            {isSuccess && <div className="m-1 text-end"><strong>{guestData?.data?.totalElements}</strong> 건의 결과가 있습니다.</div>}
            {isError && createFetchErrorMessage(error).message}
            {isSuccess && guestData?.data?.totalElements === 0 && <div className="text-center m-5 text-body-secondary">해당하는 예약자가 없습니다.</div>}
            {isSuccess &&
                <div className="guestList  mt-2">
                    {guestData?.data?.list.map((item) => {
                        return (
                            <div className={`guest bg-white  rounded shadow-sm p-2 ${item.state} `} key={`${item.id}`}>
                                <div className="m-2">
                                    <span className={`me-2 badge guestState ${item.state}`}>{GUEST_STATE[item.state]}</span>
                                    <div>
                                        <small className="text-success">{item.completedAt && moment(item.completedAt).format('YYYY.MM.DD hh:mm')}</small>
                                    </div>
                                </div>
                                <div className="guestInfo">
                                    <div className="name"><strong>{item.name}</strong> 등 <strong>{item.guestCount}</strong>명</div>
                                    <div className="tel">
                                        {item.phone}
                                    </div>
                                </div>
                                <div className="reserveInfo">
                                    {!id &&
                                        <div className="date">
                                            <Link to={`/admin/reserve/${item.date}`}>
                                                {item.date}
                                            </Link>
                                        </div>
                                    }
                                    <div className="date createdAt">
                                        <small>{moment(item.createdAt).format('YYYY.MM.DD hh:mm:ss')}</small>
                                    </div>
                                </div>
                                {item.memo && <div className="alert alert-warning p-1 text-body-secondary fst-italic">{item.memo}</div>}
                                <GuestControl guest={item} requestData={requestData} />
                            </div>
                        );
                    })
                    }
                </div>
            }

            <Pagination page={guestData?.data?.page} totalPage={guestData?.data?.totalPages} gotoPage={setPage} />
        </div>
    );
};