import { Link, Route, Routes } from "react-router-dom"
import { ConfigInfoForm } from "./ConfigInfoForm"
import { ConfigLogicForm } from "./ConfigLogicForm";
import { ChangePassword } from "./ChangePassword";
import { LogoForm } from "./LogoForm";
import { AboutForm } from "./about/AboutForm";


export const ConfigPage = ()=>{

    return (
        <div className="configPage">
            <div className="d-flex justify-content-center m-4">
                <Link className="btn btn-primary" to="/admin/config/password">비밀번호 변경</Link>
                &nbsp;
                <Link className="btn btn-secondary" to="/admin/config/info">기본정보</Link>
                &nbsp;
                <Link className="btn btn-secondary" to="/admin/config/logic">운영정책</Link>
                &nbsp;
                <Link className="btn btn-secondary" to="/admin/config/logo">로고 변경</Link>
            </div>
            
            <Routes>
                <Route path="/password" element={<ChangePassword />} />
                <Route path="/info" element={<ConfigInfoForm />} />
                <Route path="/logic" element={<ConfigLogicForm />} />
                <Route path="/logo" element={<LogoForm />} />
                <Route path="/about" element={<AboutForm />} />
            </Routes>
        </div>
    )
};