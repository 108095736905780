import { Link, NavLink } from "react-router-dom"
import { useAuth } from "../utils/hooks/useAuth";
import { ConfigContext } from "../utils/ConfigProvider";
import { useContext } from "react";
import { TelButton } from "./TelButton";


export const Header = () => {
    const { user, logout } = useAuth();
    const { configInfo } = useContext(ConfigContext);

    const onLogout = () => {
        logout();
    };
    
    return (
        <div className="header">
            <div className="pageContainer headerBody">
                <div className="headerInner">
                    <h1 className="title text-center">
                        <Link to="/">
                            <img src="/repository/logo.png" className="logo" alt="logo" />
                            {configInfo?.name} 예약
                        </Link>
                    </h1>
                    <div className="help text-end">
                        <TelButton />
                        {user !== undefined && user !== null &&
                        <button className="btn btn-sm btn-danger ms-3" onClick={onLogout}>로그아웃</button>
                        }
                    </div>
                </div>
                <ul className="gnb nav nav-tabs nav-justified">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/reserve">예약</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/about">소개</NavLink>
                    </li>
                    {user !== undefined && user !== null &&
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/admin">관리</NavLink>
                        </li>
                    }
                </ul>
            </div>
        </div>
    )
}