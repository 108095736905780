import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './scss/index.scss';
import 'moment/locale/ko';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko';
import { ConfigProvider } from './utils/ConfigProvider';
registerLocale('ko', ko);
setDefaultLocale(ko);


const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 10,
            onError: (err) => {
                console.log(err);
            },
            networkMode: 'online',
            retry: false,
        }
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <BrowserRouter >
            <ConfigProvider>
                <HelmetProvider>
                    <App />
                </HelmetProvider>
            </ConfigProvider>
            <ToastContainer stacked position="bottom-center" autoClose={5000} />
        </BrowserRouter>
    </QueryClientProvider>
    // </React.StrictMode>
);
reportWebVitals();
