import { useEffect, useState } from "react";
const { kakao } = window;

export const KakaoMap = () => {
    const [width, setWidth] = useState(360);

    useEffect(() => {
        var container = document.getElementById('map');
        var options = {
            center: new kakao.maps.LatLng(35.09369691516586, 128.72794411602086),
            level: 3

        };

        var map = new kakao.maps.Map(container, options);

        // 마커가 표시될 위치입니다 
        var markerPosition = new kakao.maps.LatLng(35.09369691516586, 128.72794411602086);

        // 마커를 생성합니다
        var marker = new kakao.maps.Marker({
            position: markerPosition
        });

        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
    }, []);


    useEffect(() => {

        function updateDimensions() {
            setWidth(Math.min(window.innerWidth - 20, 520));
        }
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
        }
    }, []);


    return (
        <div className="mapContainer">
            <div id="map" className="map border rounded" style={{ width: `${width}px`, height: '360px' }}>
            </div>
        </div>
    )
}