import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Api } from "./api";

export const UserContext = React.createContext(null);
export const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(undefined);
    
    // const [registerFormOpen, setRegisterFormOpen] = useState(false);

    useEffect(()=>{
        if(localStorage.getItem("user")===null)return setUser(null);
        Api.get("/api/auth/info").then(res=>{
            setUser(res.data);
        }).catch(err=>{
            setUser(null);
        })
        
    }, [navigate]);

    return (
        <UserContext.Provider value={{ user, setUser }} >{children}</UserContext.Provider>
    )
}