import moment from 'moment';
import { jwtDecode } from 'jwt-decode';
import Axios from 'axios';

export const refreshToken = async (config)=>{
    const expireAt = localStorage.getItem('expireAt');
    const accessToken = localStorage.getItem('user');
    const refreshToken = localStorage.getItem('refreshToken');
    
    if(accessToken===null || refreshToken===null || expireAt === null) return config;
    
    if(moment(expireAt*1000).diff(moment()) <=0 ){
        try{
            const reissueData = await Axios.post(`${process.env.REACT_APP_API_HOST}/api/auth/reissue`, null, {
                withCredentials:true,
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + accessToken,
                    'Refresh-Token': 'Bearer' + refreshToken
                }
            });
            if(reissueData.status!==200)return;
            const newAccessToken = await reissueData?.data;
        
            const tokenData = jwtDecode(newAccessToken);
            localStorage.setItem('user', newAccessToken);
            localStorage.setItem('expireAt', tokenData.exp);
    
            // 토큰을 교체
            config.headers["Authorization"] = `Bearer${newAccessToken}`;

        }catch(ex){
            localStorage.removeItem('user');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('sseToken');
        }
    }else{
        config.headers["Authorization"] = `Bearer${accessToken}`;
    }
    return config;
}


export const refreshTokenError = (error)=>{
    console.log('갱신실패');
    return Promise.reject(error);
};

