import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Api, errorToast } from "../../utils/api";
import { Loading } from "../../common/Loading";
import { useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const initialState = {
    name: '',
    desc: '',
    tel: '',
    owner: '',
    address: '',
    bizno: '',
    bank:'',
    band:'',
    homepage:'',
};

const formReducer = (state, action) => {
    return { ...state, ...action };
};


export const ConfigInfoForm = () => {
    const [state, setState] = useReducer(formReducer, initialState);
    const { data: config, isLoading, isSuccess } = useQuery({
        queryKey: ['config/info'],
        queryFn: () => Api.get('/api/config/config-info'),
        refetchOnMount: 'always'
    });

    useEffect(() => {
        if (!isSuccess) return;
        setState(config?.data);
    }, [isSuccess, config])

    const handleInput = (e) => {
        const target = e.target
        setState({ [target.name]: target.value });
    };

    const qc = useQueryClient();
    const storeMutate = useMutation({
        mutationFn: (data) => Api.post('/api/manage/config/store-info', data),
        onSuccess: (res) => {
            qc.invalidateQueries({ queryKey: ['config/info'] });
            toast.success("저장 했습니다.")
        },
        onError: errorToast
    });

    const onStore = (e) => {
        e.preventDefault();
        if (storeMutate.isPending) return;

        storeMutate.mutate(state);
    }

    if (isLoading) return <Loading />;

    return (
        <div className="configFormPage mt-3">
            <div className="shadow p-3 m-2 bg-white border rounded">
                <form onSubmit={onStore}>
                    <h2 className="title mb-5 text-center">
                        기본정보 수정
                    </h2>
                    <div className="mb-2 row">
                        <label htmlFor="name" className="col-sm-3 col-form-label">이름</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="name" id="name" maxLength={10} value={state.name} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="desc" className="col-sm-3 col-form-label">소개</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="desc" id="desc" maxLength={255} value={state.desc} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="tel" className="col-sm-3 col-form-label">연락처</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="tel" id="tel" maxLength={13} value={state.tel} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="name" className="col-sm-3 col-form-label">대표자</label>
                        <div className="col-sm-9">
                            <input type="owner" className="form-control" name="owner" id="owner" maxLength={10} value={state.owner} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="address" className="col-sm-3 col-form-label">주소</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="address" id="address" maxLength={255} value={state.address} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="bizno" className="col-sm-3 col-form-label">사업자번호</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="bizno" id="bizno" maxLength={20} value={state.bizno} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="bank" className="col-sm-3 col-form-label">입금계좌</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="bank" id="bank" maxLength={30} value={state.bank} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="band" className="col-sm-3 col-form-label">밴드</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="band" id="band" maxLength={100} value={state.band} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="homepage" className="col-sm-3 col-form-label">홈페이지</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name="homepage" id="homepage" maxLength={100} value={state.homepage} onChange={handleInput} />
                        </div>
                    </div>

                    <div className="text-center mt-3">
                        <button className="btn btn-success" onClick={onStore}>
                            {storeMutate.isPending && <Loading inline={true} />} 저장
                        </button>
                        &nbsp;
                        <Link className="btn btn-secondary" to="/admin">목록</Link>
                    </div>
                </form>
            </div>
        </div>
    )
}