import { useState } from "react";
import { GuestList } from "./GuestList";
import moment from "moment";

export const GuestListPage = () => {
    const [state, setState] = useState("WATTING");
    const [inputKeyword, setInputKeyword] = useState("");
    const [keyword, setKeyword] = useState("");
    const [date, setDate] = useState("future");
    const today = moment().format("YYYY-MM-DD");

    // 엌... ㅠ
    const onSearch = () => {
        setKeyword(inputKeyword);
    }

    const onSearchOlddate = () => {
        setDate(today);
        setState("");
    }

    const onKeywordKeyDown = (e) => {
        if (e.key === 'Enter') onSearch();
    }


    return (
        <div className="mt-4 adminGuestPage">
            <div className="d-flex justify-content-center search">

                <div className="form-check">
                    {/* 전체 */}
                    <input type="checkbox" className="btn-check" id="btnDateAll" checked={date === ""} onChange={() => setDate("")} />
                    <label className="btn" htmlFor="btnDateAll">전체</label>

                    <input type="checkbox" className="btn-check" id="btnDateFuture" checked={date === "future"} onChange={() => setDate("future")} />
                    <label className="btn" htmlFor="btnDateFuture">출항예정</label>

                    {/* 오늘 */}
                    <input type="checkbox" className="btn-check" id="btnToday" checked={date === "today"} onChange={() => setDate("today")} />
                    <label className="btn" htmlFor="btnToday">오늘</label>

                    {/* 과거 */}
                    <input type="checkbox" className="btn-check" id="btnOldDate" checked={date === today} onChange={onSearchOlddate} />
                    <label className="btn" htmlFor="btnOldDate">출항완료</label>
                </div>
                <div className="form-check">
                    <input className="btn-check" type="checkbox" value="" id="stateAll" checked={state === ""} onChange={() => setState("")} />
                    <label className="btn" htmlFor="stateAll">전체</label>

                    <input className="btn-check" type="checkbox" value="COMPLETE" id="stateComplete" checked={state === "COMPLETE"} onChange={() => setState(state === "COMPLETE" ? "" : "COMPLETE")} />
                    <label className="btn" htmlFor="stateComplete">입금</label>

                    <input className="btn-check" type="checkbox" value="WATTING" id="stateWatting" checked={state === "WATTING"} onChange={() => setState(state === "WATTING" ? "" : "WATTING")} />
                    <label className="btn" htmlFor="stateWatting">미입금</label>
                </div>

            </div>
            <div className="row justify-content-center m-3">
                <div className="col-8 col-sm-5">
                    <input type="text" className="form-control" value={inputKeyword} onChange={e => setInputKeyword(e.target.value)} onKeyDown={onKeywordKeyDown} />
                </div>
                <div className="col-3">
                    <button className="btn btn-success" onClick={onSearch}>검색</button>
                </div>
            </div>

            <GuestList state={state} keyword={keyword} guestState={state} date={date} />

        </div>
    );
};