import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Api } from "./api";
import { Loading } from "../common/Loading";

export const ConfigContext = React.createContext();
export const ConfigProvider = ({children})=>{
    const [configInfo, setConfigInfo] = useState(null);
    const [configLogic, setConfigLogic] = useState(null);

    const infoQuery = useQuery({
        queryKey: ['config/info'],
        queryFn: () => Api.get('/api/config/config-info'),
        staleTime: 1000 * 60 * 60,
        gcTime: 1000 * 60 * 60,
    });

    const logicQuery = useQuery({
        queryKey:['config/logic'],
        queryFn:()=>Api.get('/api/config/config-logic'),
        staleTime: 1000*60*60,
        gcTime: 1000*60*60,
    });

    useEffect(() => {
        if(infoQuery?.isSuccess && logicQuery?.isSuccess){
            setConfigInfo( infoQuery?.data?.data );
            setConfigLogic( logicQuery?.data?.data );
        }
    }, [infoQuery, logicQuery])

    return (
        <ConfigContext.Provider value={{configInfo, configLogic}}>
            { ( infoQuery?.isLoading || logicQuery?.isLoading ) && <Loading /> }
            { infoQuery?.isSuccess && logicQuery?.isSuccess && children }
        </ConfigContext.Provider>
    );
}
