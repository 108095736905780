import { useMutation } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { Api, errorToast } from "../../utils/api";
import { toast } from "react-toastify";
import { Loading } from "../../common/Loading";

export const LogoForm = ()=>{
    const [imagePreview, setImagePreview] = useState(null);
    const imageFileRef = useRef();
    
    const onChangeFile = (event) => {
        if(imageFileRef.current.files.length===0){
            setImagePreview(null);
            return;
        }
        
        const file = imageFileRef.current.files[0];
        if(file===null || (file.type!=='image/jpeg' && file.type!=='image/png')){
            setImagePreview(null);
            return;
        }

        setImagePreview(null);

        let reader = new FileReader();
        reader.onload = function () {
            setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
    }

    //저장관 관련된
    const storeMutate = useMutation({
        mutationFn:(requestData)=>Api.post(`/api/manage/image/store-logo`, requestData.data, {
            headers:{
                "X-Requested-With": "XMLHttpRequest",
                'Content-Type': "multipart/form-data"
            },
        }),
        onSuccess:(res)=>{
            toast.success("저장했습니다.");
        },
        onError:errorToast
    });    

    const onStore = (event)=>{
        if(storeMutate.isPending)return;
        let formData = new FormData();
        if(imageFileRef.current.value){
            formData.append('imageFile', imageFileRef.current.files[0]);
        }else{
            alert('파일이 선택되지 않았습니다.');
            return;
        }
        storeMutate.mutate({data:formData});
    }

    return (
        <div className="configFormPage logoForm mt-3">
            <div className="shadow m-1 p-3 bg-white border rounded">
                <div className="text-center">
                    {imagePreview!==null && <img src={imagePreview} className="img-thumbnail rounded" alt="첨부파일 이미지 미리보기" /> }
                </div>
                <input type="file" ref={imageFileRef} name="image_file" className="form-control mt-3" accept="image/png, image/jpeg"  onChange={onChangeFile} />
                <div className="text-body-secondary mt-1">* 로고는 새로고침을 해야하며, 브라우저 설정에 따라 캐시된 이미지가 표시될 수 있습니다.</div>
                <div className="text-center mt-4">
                    <button className="btn btn-primary" onClick={onStore}>
                        {storeMutate.isPending && <Loading inline={true} />}
                        저장
                    </button>
                </div>
            </div>
        </div>
    );
}