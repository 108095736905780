import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Api } from "../../utils/api";
import { Loading } from "../../common/Loading";
import { RESERVE_STATE, WEEK_NAMES } from "../../utils/const";
import { ReserveControl } from "./ReserveControls";
import { GuestList } from "../guest/GuestList";


export const AdminReserveView = () => {
    const { date } = useParams();
    // const navigate = useNavigate();
    
    const { data: reserve, isLoading } = useQuery({
        queryKey: ['reserve', date],
        queryFn: () => Api.get(`/api/reserve/date/${date}`),
        refetchOnMount: 'always',
        staleTime: 1000 * 5,
        gcTime: 1000 * 5,
    });
    

    if(isLoading) return <Loading />;
    
    const year = reserve?.data?.date.substring(0, 4);
    const month = reserve?.data?.date.substring(5, 7);


    return (
        <div>
            <h1 className="text-center mt-3">{reserve?.data?.date} <small>({WEEK_NAMES[reserve?.data?.week]})</small></h1>
            <div className="m-2 mt-3 bg-white p-2 border rounded shadow-sm">
                <div className="container">
                    <div className="mb-3 row">
                        <label className="col-4 col-sm-2 ">인원</label>
                        <div className="col-8 col-sm-10 form-text">
                            {reserve?.data?.guestCount}
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-4 col-sm-2 ">상태</label>
                        <div className={`reserveState ${reserve?.data?.state} col-8 col-sm-10 form-text`}>
                            { RESERVE_STATE[reserve?.data?.state]}
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-4 col-sm-2 ">어종</label>
                        <div className="col-8 col-sm-10 form-text">
                            {reserve?.data?.fish}
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-4 col-sm-2 ">인원</label>
                        <div className="col-8 col-sm-10 form-text">
                            {reserve?.data?.guestCount}/{reserve?.data?.totalCount}
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-4 col-sm-2 ">비용</label>
                        <div className="col-8 col-sm-10 form-text">
                            {reserve?.data?.reservePrice} / {reserve?.data?.price} 만원 <small>(1인 예약/선비)</small>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <label className="col-4 col-sm-2 ">메모</label>
                        <div className="col-8 col-sm-10">
                            {reserve?.data?.memo || ""}
                        </div>
                    </div>
                </div>

                <hr />

                <ReserveControl reserve={reserve?.data} />

                <hr />

                <div className="text-center">
                    <Link className="btn btn-primary" to={`/admin/reserve/update?date=${reserve?.data?.date}&year=${year}&month=${month}`} >수정</Link>
                    &nbsp;
                    <Link className="btn btn-secondary" to={`/admin/reserve?year=${year}&month=${month}`} >목록</Link>
                </div>
            </div>
            
            <GuestList id={reserve?.data?.id} />
        </div>
    )
}