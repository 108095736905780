import { useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { UserContext } from "../AuthProvider";
import { jwtDecode } from "jwt-decode";

export const useAuth = ()=>{
    const { user, setUser } = useContext(UserContext);
    const { setItem, removeItem } = useLocalStorage();
  
    const login = (loginData) => {
        let tokenData = jwtDecode(loginData.token);
        setItem("user", loginData.token);
        setItem("refreshToken", loginData.refreshToken);
        setItem('expireAt', tokenData.exp);
        
        setUser(loginData.user);
    };
  
    const logout = () => {
        removeItem("user");
        removeItem("refreshToken");
        removeItem('expireAt');
        setUser(null);
    };

  
    return { user, login, logout, setUser };
}