import { useCallback, useContext, useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import { Api, errorToast } from "../utils/api";
import { Loading } from "../common/Loading";
import { toast } from 'react-toastify';
import { WEEK_NAMES } from "../utils/const";
import { ReserveResult } from "./ReserveResult";
import { ConfigContext } from "../utils/ConfigProvider";
import moment from "moment";

export const ReserveForm = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { configInfo, configLogic } = useContext(ConfigContext);

    const [guestCount, setGuestCount] = useState(1);
    const [name, setName] = useState("");
    const [tel, setTel] = useState("010-");
    const [password, setPassword] = useState('');
    const [maxGuestCount, setMaxGuestCount] = useState(10);
    const [agreeChecked, setAgreeChecked] = useState(false);

    const [guest, setGuest] = useState(null);

    const qc = useQueryClient();

    const { data: reserve, isSuccess, isLoading, isError } = useQuery({
        queryKey: ['reserve', id],
        queryFn: () => Api.get(`/api/reserve/${id}`),
        refetchOnMount: 'always',
    });

    useEffect(() => {
        if (!isSuccess) return;
        //최대 예약가능인원 계산
        const tmpCount = reserve?.data?.totalCount - reserve?.data?.guestCount;
        if (tmpCount !== guestCount) setMaxGuestCount(tmpCount);
        if (guestCount > tmpCount) setGuestCount(tmpCount);
        //날짜를 경과 했는지 검사
        // 4개월 4, 5, 6, 7
        // 3=1 4=0, 5-1, 6-2 7-3
        const diffDay = moment().startOf('day').diff(moment(reserve?.data?.date), 'day');
        try{
            if(diffDay>0) throw new Error("예약기간이 아닙니다");
                        
            if(diffDay===0){
                //당일은 시간을 비교
                let reserveTime = Number(configLogic?.reserveTime);
                if( Number.isNaN(reserveTime) || reserveTime>23 ) reserveTime=0;
                if( moment().diff(moment(reserve?.data?.date).hours(reserveTime), 'hours')>0 ){
                    throw new Error("예약 가능 시간이 지났습니다");
                }
            }
            //미래면
            const diffMonth = moment().date(0).diff(moment(reserve?.data?.date), 'month');
            if(diffMonth < (Number(configLogic.month)*-1+1) ){
                throw new Error("예약기간이 아닙니다");
            }
        }catch(ex){
            toast.error(ex.message);
            navigate(-1);
        }
        
    }, [reserve, isSuccess, guestCount, configLogic, navigate])

    const onChangeTel = useCallback((e) => {
        let tempValue = e.target.value.replace(/[^0-9]/g, "");
        if (tempValue.length <= 7) {
            setTel(tempValue.replace(/^(\d{3})(\d{1,4})$/, `$1-$2`));
        } else {
            setTel(tempValue.replace(/^(\d{3})(\d{3,4})(\d{2,4})$/, `$1-$2-$3`));
        }
    }, []);

    const storeMutate = useMutation({
        mutationFn: (data) => Api.post("/api/guest/create", data),
        onSuccess: (res) => {
            //예약완료 이동
            setGuest(res.data);
            qc.invalidateQueries({queryKey:['reserve/list', { 
                            year:res.data.reserveDate.substring(0, 4), 
                            month:res.data.reserveDate.substring(5, 7) }]});
            toast.success('예약되었습니다.');
        },
        onError: errorToast

    });

    const onReserve = () => {
        if (storeMutate.isPending) return;
        if (name.length < 2) return toast.error("예약자 이름을 입력해주세요");
        if (tel.length < 12) return toast.error("휴대폰 번호를 입력해주세요");
        if ( configLogic?.useReserveFind==='Y' && password.length < 4) return toast.error("비밀번호를 4자리 이상 등록해주세요");
        if (!agreeChecked) return toast.error("안내사항을 확인후 동의해 주세요.");

        storeMutate.mutate({ id, name, password, tel, guestCount });
    }

    if (isLoading) return <Loading />;
    if (isError) {
        return (
            <div>
                불러오기에 실패했습니다.
            </div>
        )
    }

    const year = reserve?.data?.date.substring(0, 4);
    const month = reserve?.data?.date.substring(5, 7);

    if(guest) return <ReserveResult guest={guest} />

    return (
        <div className="reserveFormPage">

            <div className="reserveForm container mt-1 ">
                <div className="shadow p-3 mb-4 bg-white border rounded">
                    <h2 className="title text-center">
                        <span className="date">{reserve?.data?.date} <small>({WEEK_NAMES[reserve?.data?.week]})</small></span> 예약
                    </h2>

                    <div className="mb-2 row">
                        <label className="col-sm-2 col-form-label">어종</label>
                        <label className="col-sm-10 col-form-label">{reserve?.data?.fish}</label>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="name" className="col-sm-2 col-form-label">이름</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="name" maxLength={5} value={name} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>

                    <div className="mb-2 row">
                        <label htmlFor="tel" className="col-sm-2 col-form-label">연락처</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" id="tel" value={tel} onChange={onChangeTel} maxLength={13} />
                        </div>
                    </div>

                    { configLogic?.useReserveFind==='Y' &&
                    <div className="mb-2 row">
                        <label htmlFor="password" className="col-sm-2 col-form-label">비밀번호</label>
                        <div className="col-sm-10">
                            <input type="password" className="form-control" id="password" placeholder="비밀번호 - 예약 조회/취소에 이용됩니다."  value={password} onChange={e=>setPassword(e.target.value)} maxLength={10} />
                        </div>
                    </div>
                    }

                    <div className="mb-2 row">
                        <label htmlFor="guestCount" className="col-12 col-form-label">
                            인원을 선택해주세요.
                            <small className="text-body-secondary fst-italic"> {maxGuestCount}명 까지 가능합니다. </small>
                        </label>
                        <div className="col-12">
                            <div className="guestButtons">
                                {
                                    new Array(maxGuestCount).fill().map((item, key) => {
                                        const num = key + 1;
                                        return <button key={num} className={`btn btn-${guestCount === num ? "" : "outline-"}primary`} onClick={() => setGuestCount(num)}>{num}</button>;
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="m-3 text-center">
                    <span>선비: <strong>{reserve?.data?.price*guestCount}만원</strong></span>
                        { reserve?.data?.price*guestCount!== reserve?.data?.reservePrice*guestCount &&
                        <>
                        ,&nbsp;&nbsp;
                        <span>예약금: <strong>{reserve?.data?.reservePrice*guestCount}만원</strong></span>
                        </>
                        }
                    </div>
                </div>
                <div className="reserveGuide mb-2">
                    <h5>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-square me-1" viewBox="0 0 16 16">
                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                        </svg>
                        예약 시 확인해 주세요
                    </h5>
                    <ul className=" border border-secondary-subtle rounded shadow bg-white">
                        <li>선비: <strong>{reserve?.data?.price*guestCount}만원</strong>
                        { reserve?.data?.price*guestCount!== reserve?.data?.reservePrice*guestCount &&
                            <>, 예약금: <strong>{reserve?.data?.reservePrice*guestCount}만원</strong></>
                        }
                        
                        입니다.
                        </li>
                        <li>입금 되어야 예약 확정됩니다.</li>
                        <li>입금자명은 작성한 예약자명과 동일하게 해주세요</li>
                        <li>입금이 안되면 <strong className="text-danger">예약 취소</strong> 될 수 있습니다</li>
                        <li><strong className="text-danger">예약취소</strong>는 반드시 <strong>전화</strong> 주세요.</li>
                        <li>온라인/전화 예약이 중복 발생할 경우 예약순서에 따라 취소 될 수 있습니다.</li>
                        <li>계좌 : <strong>{configInfo?.bank}</strong></li>
                    </ul>
                    <h5>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check2-square me-1" viewBox="0 0 16 16">
                            <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5z" />
                            <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0" />
                        </svg>
                        취소 및 환불
                    </h5>
                    <ul className=" border border-secondary-subtle rounded shadow bg-white">
                        <li>7일전 100% 환불</li>
                        <li>3일전 50% 환불</li>
                        <li>1일전 & 당일 환불 불가</li>
                    </ul>
                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="agree" checked={agreeChecked} onChange={e => setAgreeChecked(e.target.checked)} />
                    <label className="form-check-label" htmlFor="agree"> 안내사항에 동의합니다.</label>
                </div>
                <div className="text-center mt-3">
                    <button className={`btn btn-success ${ agreeChecked?"":"disabled"}`} onClick={onReserve}>
                        {storeMutate.isPending && <Loading inline={true} />} 예약하기
                    </button>
                    &nbsp;
                    <Link className="btn btn-secondary" to={`/?year=${year}&month=${month}`} >목록</Link>
                </div>
            </div>


        </div>
    )
};