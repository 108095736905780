import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Api, createFetchErrorMessage } from "../../utils/api";
import { toast } from "react-toastify";
import { ReserveMemoForm } from "./ReserveMemoForm";

export const ReserveControl = ({ reserve }) => {

    const qc = useQueryClient();

    const updateStateMutate = useMutation({
        mutationFn: (data) => Api.post("/api/manage/reserve/update-state", data),
        onSuccess: (res) => {
            qc.setQueryData(['reserve', reserve.date], (oldData) => {
                return { ...oldData, data: { ...oldData.data, state: res.data } };
            })
            toast.success('변경되었습니다.');
        },
        onError: (err) => {
            const errorData = createFetchErrorMessage(err);
            toast.error( errorData.message );
        }
    });

    const onChangeState = (state) => {
        if (updateStateMutate.isPending) return;
        if( !window.confirm("변경 하시겠습니까?")) return;
        updateStateMutate.mutate({ id:reserve.id, state })
    }
    

    return (
        <div className="text-center m-3 pt-3">
            <ReserveMemoForm id={reserve.id} date={reserve.date} />
            <div className="mb-3">
                {reserve.state !== "OPEN" && <button className="btn btn-primary" onClick={() => onChangeState("OPEN")}>예약</button>}
                &nbsp;
                {reserve.state !== "COMPLETE" && <button className="btn btn-success" onClick={() => onChangeState("COMPLETE")}>마감</button>}
                &nbsp;
                {reserve.state !== "CLOSE" && <button className="btn btn-secondary" onClick={() => onChangeState("CLOSE")}>중지</button>}
                &nbsp;
                {/* {reserve.state !== "cancel" && <button className="btn btn-danger" onClick={() => onChangeState("CANCEL")}>취소</button>} */}
            </div>

        </div>
    )
}