import { useMemo, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ReactDatePicker from "react-datepicker"
import { Api, errorToast } from "../../utils/api";
import { Loading } from "../../common/Loading";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { useQueryString } from "../../utils/useQueryString";

export const ReserveUpdateForm = () => {
    const navigate = useNavigate();
    const [searchStdate, setSearchStdate] = useState(new Date());
    const [searchEddate, setSearchEddate] = useState(moment().add(30, 'days').toDate());
    const [totalCount, setTotalCount] = useState(20);
    const [fish, setFish] = useState('')
    const [price, setPrice] = useState(20);
    const [reservePrice, setReservePrice] = useState(10);

    const qs = useQueryString();

    const requestData = useMemo(() => {
        const year = qs.year || String(new Date().getFullYear());
        const month = qs.month || String(new Date().getMonth() + 1).padStart(2, "0");
        if(qs?.date){
            setSearchStdate( new Date(qs.date) );
            setSearchEddate( new Date(qs.date) );
        }
        return { year, month };
    }, [qs]);

    
    const qc = useQueryClient();

    const storeMutate = useMutation({
        mutationFn:(data)=>Api.post('/api/manage/reserve/update-reserve', data),
        onSuccess:(res)=>{
            qc.invalidateQueries({queryKey:['reserve/list', requestData]});
            toast.success(`${res?.data}건을 변경 했습니다.`);
            navigate("/admin/reserve");
        },
        onError:errorToast
    });

    const onStore = ()=>{
        if(storeMutate.isPending)return;
        // if(!window.confirm("변경 하시겠습니까?"))return;
        storeMutate.mutate({totalCount, fish, price, reservePrice,
            stdate:searchStdate.toISOString().substring(0, 10), eddate:searchEddate.toISOString().substring(0, 10)});
    }

    return (
        <div className="pageContainer m-2 mt-3 bg-white p-3 border rounded shadow-sm">
            <div className="mt-3 row">
                <label className="col-2 col-sm-2 col-form-label">기간</label>
                <div className="col-5 col-sm-5">
                    <ReactDatePicker selected={searchStdate} selectsStart onChange={(date) => setSearchStdate(date)}
                        startDate={searchStdate} endDate={searchEddate}
                        closeOnScroll={true}
                        dateFormat="yyyy-MM-dd" className="form-control" placeholderText="시작" />
                </div>
                <div className="col-5 col-sm-5">
                    <ReactDatePicker selected={searchEddate} selectsEnd onChange={(date) => setSearchEddate(date)}
                        startDate={searchStdate} endDate={searchEddate}
                        closeOnScroll={true}
                        dateFormat="yyyy-MM-dd" className="form-control" placeholderText="종료" />
                </div>
            </div>

            <div className="mt-3 row">
                <label htmlFor="totalCount" className="col-2 col-sm-2 col-form-label">인원</label>
                <div className="col-9 col-sm-10">
                    <input type="number" className="form-control" name="totalCount" value={totalCount} onChange={e=>setTotalCount(e.target.value)} />
                </div>
            </div>

            <div className="mt-3 row">
                <label htmlFor="fish" className="col-2 col-sm-2 col-form-label">어종</label>
                <div className="col-9 col-sm-10">
                    <input type="text" className="form-control" name="fish" value={fish} onChange={e=>setFish(e.target.value)} maxLength={20} />
                </div>
            </div>


            <div className="mt-3 row">
                <label htmlFor="price" className="col-2 col-sm-2 col-form-label">선비</label>
                <div className="col-3 col-sm-3">
                    <input type="number" className="form-control" name="price" value={price} maxLength={2} onChange={e=>setPrice(e.target.value)} />
                </div>
                <label htmlFor="reservePrice" className="col-3 col-sm-2 col-form-label">예약금</label>
                <div className="col-3 col-sm-3">
                    <input type="number" className="form-control" name="reservePrice" value={reservePrice} maxLength={2} onChange={e=>setReservePrice(e.target.value)} />
                </div>
            </div>

            <div className="text-center mt-4">
                <button className="btn btn-success" onClick={onStore}>
                    {storeMutate.isPending && <Loading inline={true} />} 저장
                </button>
                &nbsp;
                <Link className="btn btn-secondary" to="/admin/reserve" >목록</Link>
            </div>

        </div>
    )
}