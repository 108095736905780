import { NavLink } from "react-router-dom"


export const AdminGnb = ()=>{
    

    return (
        <ul className="gnb admin nav nav-tabs nav-justified shadow-sm mt-2">
            <li className="nav-item">
                <NavLink className="nav-link" to="/admin" end>홈</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/admin/config">설정</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/admin/reserve">예약일</NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" to="/admin/guest">예약자</NavLink>
            </li>
        </ul>
    )
}