
export const WEEK_NAMES = ['일', '월', '화', '수', '목', '금', '토', '일'];

export const RESERVE_STATE = {
    OPEN:'예약중',
    COMPLETE:'예약마감',
    CLOSE:'예약불가',
    CANCEL:'취소'
};

export const GUEST_STATE = {
    WATTING:'미입금',
    COMPLETE:'입금완료',
    CANCEL:'예약취소',
};