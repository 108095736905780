import { useEffect, useState } from "react"
import { useMutation } from "@tanstack/react-query";
import { Api, errorToast } from "../utils/api";
import { GuestInfo } from "./GuestInfo";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useQueryString } from "../utils/useQueryString";

export const ReserveFind = () => {
    const { id } = useQueryString();
    const [guestId, setGuestId] = useState('');
    const [password, setPassword] = useState('');
    const [guest, setGuest] = useState(null);

    useEffect(() => {
        if(id) setGuestId(id);
    }, [id])

    // 조회
    const reserveFindMutate = useMutation({
        mutationFn: (data) => Api.post('/api/guest/find', data),
        onSuccess: (res) => {
            setGuest(res.data);
        },
        onError: errorToast
    });

    const onFind = (e) => {
        e.preventDefault();
        if (reserveFindMutate.isPending) return;

        reserveFindMutate.mutate({ guestId, password });
    }

    const deleteMutate = useMutation({
        mutationFn:(data)=>Api.post('/api/guest/cancel', data),
        onSuccess: (res) => {
            toast.success("삭제했습니다.");
            // navigate("/");
        },
        onError: errorToast
    });

    const onDelete = ()=>{
        if(deleteMutate.isPending)return;
        if(!window.confirm("예약을 취소하시겠습니까?\n예약금을 입금한 경우 선장과 협의후 진행해 주세요.")) return;
        deleteMutate.mutate({guestId, password});
    };

    return (

        <div className="reserveForm container mt-1 ">
            <div className="shadow p-3 mb-4 bg-white border rounded">
                <h2 className="title text-center">
                    예약 확인 
                </h2>
                <div className="mb-2 row">
                    <label htmlFor="guestId" className="col-sm-2 col-form-label">예약번호</label>
                    <div className="col-sm-10">
                        <input type="text" className="form-control" id="guestId" maxLength={14} value={guestId} onChange={e => setGuestId(e.target.value)} autoComplete="off" />
                    </div>
                </div>

                <div className="mb-2 row">
                    <label htmlFor="password" className="col-sm-2 col-form-label">비밀번호</label>
                    <div className="col-sm-10">
                        <input type="password" className="form-control" id="password" placeholder="비밀번호를 입력해주세요." value={password} onChange={e => setPassword(e.target.value)} maxLength={10} autoComplete="off" />
                    </div>
                </div>

                <div className="mb-4 text-center">
                    <button className="btn btn-success" onClick={onFind}>예약조회</button>
                    &nbsp;
                    {guest && 
                    <button className="btn btn-danger" onClick={onDelete}>
                        예약취소
                    </button>
                    }
                    &nbsp;
                    <Link className="btn btn-secondary" to="/" >목록</Link>
                </div>
                {guest && <GuestInfo guest={guest} /> }
            </div>
        </div>
    );
}