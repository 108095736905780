import { memo } from 'react';



export const Pagination = memo(({page=0, totalPage=1, gotoPage=f=>f})=>{

    let startNum = Math.max(page-5, 0);
    let endNum = Math.min(page+5, (totalPage) ) ;
    if(endNum===0)endNum=1;

    //이전
    let previousPageButton = <li className=" disabled"><button>이전</button></li>;
    if(page>0){
        previousPageButton = <li > <button onClick={ ()=>gotoPage(page-1) }>이전</button> </li>;
    }

    //다음
    let nextPageButton = <li > <button onClick={ ()=>gotoPage(page+1) }>다음</button></li>;
    if( (page+1)===totalPage || totalPage===0 || totalPage===1 ){
        nextPageButton = <li className=" disabled"><button>다음</button></li>;
    }

    return (
        <div className="paging">
            <ul>
                {page>0 &&  <li > <button onClick={ ()=>gotoPage(0) }>처음</button> </li> }
                { previousPageButton }
                {
                    Array.apply(0, {length: (endNum-startNum) }).map(function(value, index){
                        const tmpPage = startNum + index;
                        const className = (tmpPage === page)?'on':'';
                        return (
                            <li className={className} key={tmpPage}>
                                <button onClick={ ()=>gotoPage(tmpPage)}>{tmpPage+1}</button>
                            </li>
                        )
                    })
                }
                { nextPageButton }
                { page+1<totalPage &&  <li> <button onClick={ ()=>gotoPage(totalPage-1) }>마지막</button> </li> }
            </ul>
        </div>
    );
});