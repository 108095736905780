import { useQuery } from "@tanstack/react-query";
import { Api } from "../utils/api";
import { Loading } from "../common/Loading";


export const CountSummary = ()=>{
    const { data: summary, isLoading } = useQuery({
        queryKey: ['guest/count'],
        queryFn: () => Api.get(`/api/manage/guest/summary`),
        refetchOnMount:'always',
    });

    if(isLoading) return <Loading />;

    return (
        <div className="adminSummary row row-cols-2 m-2 mt-3 bordered bg-white text-center">
            <div className="col">
                <div>전체</div>
                <div><strong>{summary?.data?.totalCount || 0}</strong></div>
            </div>
            <div className="col">
                <div>출항</div>
                <div><strong>{summary?.data?.todayCount || 0}</strong></div>
            </div>
            <div className="col">
                <div>미입금</div>
                <div><strong>{summary?.data?.wattingCount || 0}</strong></div>
            </div>
            <div className="col">
                <div>입금</div>
                <div><strong>{summary?.data?.paymentCount || 0}</strong></div>
            </div>
        </div>
    );
};