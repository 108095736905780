import { useContext } from 'react';
import { ConfigContext } from '../utils/ConfigProvider';
import { KakaoMap } from './KakaoMap';
import { TelButton } from '../common/TelButton';
import { BandButton } from '../common/BandButton';


// import ShipImage from '../images/about/ship.jpg';
// import VideoSource from '../images/about/sea.mp4';

export const About = () => {
    const { configInfo } = useContext(ConfigContext);
    
    return (
        <div className="aboutPage p-2 text-center ">
            <div className="pageContainer">
                <section className="mt-5 ">
                    <h1 className="shipName">{configInfo?.name}</h1>
                    <div>
                        <TelButton showNumber={true} />
                        <BandButton />
                    </div>

                    <img src="/images/ship.jpg" className="img-fluid rounded mx-auto m-2 mt-5 d-block" alt={configInfo?.name} />

                    <div className="boxContainer text-body-secondary">
                        <div>
                            <img className="rounded img-thumbnail" src="/images/ship/ship1.jpg" alt="안전 탑승" />
                            안전탑승
                        </div>
                        <div>
                            <img className="rounded img-thumbnail" src="/images/ship/ship2.jpg" alt="최신어로장비" />
                            최신어로장비
                        </div>
                        <div>
                            <img className="rounded img-thumbnail" src="/images/ship/ship3.jpg" alt="넓은낚시공간" />
                            넓은낚시공간
                        </div>
                        <div>
                            <img className="rounded img-thumbnail" src="/images/ship/ship4.jpg" alt="넓은 휴식공간" />
                            넓은 휴식공간
                        </div>
                    </div>
                </section>

                <section className="mt-6">
                    <h1 className="">배타는 곳</h1>
                    <span className="text-body-secondary">{configInfo?.address} </span>
                    {/* <img src={MapImage} className="img-fluid rounded mx-auto m-2 d-block" alt="지도" /> */}
                    <div className='text-center'>
                        <KakaoMap />
                    </div>
                </section>

                <section className="mt-6">
                    <h1 className="">주차안내</h1>
                    <p className="text-body-secondary ">
                        삼포항 우측 방파제 주차
                    </p>
                </section>


                <section className='mt-6'>
                    <h1 className="mb-2">준비물</h1>
                    <div>
                        신분증, 구명조끼<br />
                        낚시장비외 쿨러<br />면장갑, 멀미약, 미끼용칼등 <br />
                    </div>
                </section>
                <section className='mt-5'>
                    <h1 className="mb-2">대여품목</h1>
                    <div>
                        구명조끼(무상)<br />
                        갈치낚시대 1만원<br />
                        갈치릴 1만원<br />
                        갈치쿨러+스티로폼 박스 1만원
                    </div>
                </section>
                <section className="mt-5">
                    <h1 className="mb-2">선사제공</h1>
                    <div>
                        얼음,  식사,  간식, 라면 <br />
                        생수, 음료<br />
                        잡은 생선택배 서비스<br />
                        친절한 서비스^^등등
                    </div>
                </section>

                <section className='mt-6'>
                    <h1 className="mb-4">대상어종</h1>
                    <div className="boxContainer">
                        <img className="rounded img-thumbnail" src="/images/fish/fish0.jpg" alt="갈치" />
                        <img className="rounded img-thumbnail" src="/images/fish/fish1.jpg" alt="한치" />
                    </div>
                    <div className="seasonContainer text-body-secondary">
                        <div className="season season1">
                            <div className="bar">
                                12월 ~ 3월
                            </div>
                            <div className="text-start">
                                먼바다 <strong>오징어, 참돔타이라바</strong>
                            </div>
                        </div>
                        
                        <div className="season season2">
                            <div className="bar">
                                4월 ~ 7월
                            </div>
                            <div>
                                먼바다 <strong>한치, 갈치</strong>
                            </div>
                        </div>

                        <div className="season season3">
                            <div className="bar">
                                5월 ~ 12월
                            </div>
                            <div className="text-end">
                                먼바다 <strong>갈치</strong>
                            </div>
                        </div>

                        <div className="text-start text-body-secondary">
                            * 중복되는 시즌은 <br />잘 나오는 어종으로 출조합니다    
                        </div>
                    </div>
                </section>

            </div>
        </div>
    );
};
