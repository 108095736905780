import { Route, Routes } from "react-router-dom";
import { GuestListPage } from "./GuestListPage";
import { GuestModify } from "./GuestModify";

export const AdminGuestPage = () => {

    return (
        <div>
            <Routes>
                <Route path="/" element={<GuestListPage />} />
                <Route path="/:id" element={<GuestModify />} />
            </Routes>
            
        </div>
    );
};