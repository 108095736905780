import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { Api, errorToast } from "../../utils/api";
import { Loading } from "../../common/Loading";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const ChangePassword = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

    const passwordChangeMutate = useMutation({
        mutationFn:(data)=>Api.post('/api/manage/user/change-password', data),
        onSuccess:(res)=>{
            toast.success("비밀번호를 변경 했습니다.");
            navigate("/admin");
        },
        onError:errorToast
    });

    const onStore = ()=>{
        if( passwordChangeMutate.isPending ) return ;
        const storeData = { id: null, password, newPassword, newPasswordConfirm };
        passwordChangeMutate.mutate(storeData);
    }

    return (
        <div className="pageContainer m-2 mt-3 bg-white p-5 border rounded shadow-sm">
            <div className="mt-3 row">
                <label className="col-sm-3 col-form-label">기존 비밀번호</label>
                <div className="col">
                    <input className="form-control" type="password" name="password" value={password} maxLength="20" onChange={(e) => setPassword(e.target.value)} required={true} />
                </div>
            </div>

            <div className="mt-3 row">
                <label className="col-sm-3 col-form-label">새 비밀번호</label>
                <div className="col">
                <input className="form-control" type="password" name="newPassword" value={newPassword} maxLength="20" onChange={(e) => setNewPassword(e.target.value)} required={true} />
                </div>
            </div>

            <div className="mt-3 row">
                <label className="col-sm-3 col-form-label">새 비밀번호 확인</label>
                <div className="col">
                <input  className="form-control" type="password" name="newPasswordConfirm" value={newPasswordConfirm} maxLength="20" onChange={(e) => setNewPasswordConfirm(e.target.value)} required={true} />
                </div>
            </div>

            <div className="text-center mt-4">
                <button className="btn btn-success" onClick={onStore}>
                    {passwordChangeMutate.isPending && <Loading inline={true} />} 저장
                </button>
                &nbsp;
                <Link className="btn btn-secondary" to="/admin" >관리자 홈</Link>
            </div>

        </div>
    )
}