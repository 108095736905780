import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Api, errorToast } from "../../utils/api";
import { toast } from "react-toastify";
import { Loading } from "../../common/Loading";
import { Link } from "react-router-dom";

export const GuestControl = ({guest, requestData}) => {
    const qc = useQueryClient();

    //완료
    const completeMutate = useMutation({
        mutationFn: () => Api.get(`/api/manage/guest/${guest.id}/complete`),
        onSuccess: (res) => {
            qc.invalidateQueries({queryKey:['guest/list', requestData]});
            if(requestData.date) qc.invalidateQueries({queryKey:['reserve', requestData.date]});
            toast.success('변경되었습니다.');
        },
        onError: (err) => errorToast
    });

    const onComplete = ()=>{
        if( completeMutate.isPending)return;
        if( !window.confirm("입금 처리 하시겠습니까?"))return;
        // const memo = window.prompt("입금 메모를 작성해주세요");
        completeMutate.mutate();
    }


    // 취소
    const cancelMutate = useMutation({
        mutationFn: () => Api.get(`/api/manage/guest/${guest.id}/cancel`),
        onSuccess: (res) => {
            qc.invalidateQueries({queryKey:['guest/list', requestData]});
            if(requestData.date)qc.invalidateQueries({queryKey:['reserve', requestData.date]});
            toast.success('취소 되었습니다.');
        },
        onError: (err) => errorToast
    });

    const onCancel = ()=>{
        if( cancelMutate.isPending) return;
        if( !window.confirm("취소 하시겠습니까?"))return;
        cancelMutate.mutate();
    }

    //삭제
    const deleteMutate = useMutation({
        mutationFn: () => Api.get(`/api/manage/guest/${guest.id}/delete`),
        onSuccess: (res) => {
            qc.invalidateQueries({queryKey:['guest/list', requestData]});
            if(requestData.date)qc.invalidateQueries({queryKey:['reserve', requestData.date]});
            toast.success('삭제 되었습니다.');
        },
        onError: errorToast
    });

    const onDelete = ()=>{
        if( deleteMutate.isPending) return;
        if( !window.confirm("삭제 하시겠습니까?"))return;
        deleteMutate.mutate();
    }



    return (
        <div className="mt-2 control">
            {guest.state==='WATTING' && 
                <button className="btn btn-sm btn-outline-success" onClick={onComplete}>
                    {completeMutate.isPending && <Loading inline={true} />} 입금확인
                </button> 
            }
            
            {guest.state!=='CANCEL' && 
            <>
                <button className="btn btn-sm btn-outline-danger" onClick={onCancel}>
                    {cancelMutate.isPending && <Loading inline={true} />} 취소
                </button>
                <Link to={`/admin/guest/${guest.id}`} className="btn btn-sm btn-outline-secondary">수정</Link>
            </>
            }

            {guest.state==='CANCEL' && <button className="btn btn-sm btn-danger" onClick={onDelete}>삭제</button>}

            
        </div>
    );
}